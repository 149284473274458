<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                海通证券&非凸科技，多视角探讨前沿科技对金融市场的影响
              </p>
              <div class="fst-italic mb-2">2023年5月25日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >沙龙活动</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/40/01-主题.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    5月18日，海通证券联合非凸科技开展的“百川赴海·探渊索珠”沙龙活动在杭州顺利举行，特别邀请到管理人波粒二象，与行业伙伴共同探讨前沿金融科技在当下金融市场发挥的重要作用。
                  </p>
                  <p>
                    会上，海通证券浙江分公司总经理金晓阳对本次活动发表致辞，作为头部券商，海通证券很早就开始对专业交易团队进行服务和赋能，无论在交易工具、服务能力，还是资金直投、产品代销方面，都在市场前列。近年来，海通证券总部进行了组织架构大调整，成立了数字金融部，衍生产品与交易部，金融产品部等，从而更好地契合市场新的发展方向，以及迎合A股越来越专业化、机构化的发展趋势。此次活动，邀请到海通证券两大部门相关业务骨干带来最新业务分享，并联合非凸科技给管理人在算法交易上带来全新的体验。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/40/02-金晓阳.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    海通证券数字金融部总经理助理李游就“私募客户投资交易整体解决方案”主题进行了分享。e海方舟是海通证券自主研发，为专业投资者量身打造的一站式智能交易平台。其算法交易平台集统一对接、极速交易、统一结算、服务灵活等功能于一体，通过金融科技赋能，提升交易体验，助力客户捕捉交易机会，赢得先机。目前已覆盖包括非凸算法在内的大部分算法，主要用于主动拆单和回转套利场景。未来，双方的持续合作，将为亿万投资者带来全新的算法交易服务体验，充分满足机构客户多样化、专业化交易需求。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/40/03-李游.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    非凸科技商务总监朱家辉发表了“国内算法业务现状与展望”主题演讲。近年来，随着市场对算法交易的接受度、认可度持续升温，算法交易服务体系也逐步走向成熟。作为第三方算法服务厂商，非凸科技在算法总线的基础上，从交易场景真实需求出发，结合自身算法优势打造了一站式算法业务解决方案，包括高级绩效分析系统、数据回测系统、模拟仿真系统、实时风险管理、运维监控系统等。非凸科技与海通证券已达成长期深度合作，关注并赋能专业投资者在算法交易中的每一个链节。打造覆盖机构全生命周期的服务生态圈，是双方共同的不懈追求。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/40/04-朱家辉.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    海通证券衍生品部产品主管翁冠群就“多空收益互换及金融产品培育”主题进行了分享，重点介绍了DMA交易系统建设及服务情况。海通证券近年来重点推进场外衍生品有多空收益互换、跨境收益互换等，未来将严控权益类趋势投资规模，保持固定收益类业务平稳发展，大力发展收益互换等衍生品业务。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/40/05-翁冠群.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    波粒二象CTO余天从全新的视角分享了“Rust在投研场景中的应用”，为大家带来满满的技术干货。余天从投资行业的世界观出发，提出了收益的核心来源，即整体的收益表现包括收益能力的提升（多策略、多风格、跨市场）、策略的有效性（研发能力、自动化的参数优化和策略迭代）以及策略的投资广度（机器学习算法进行组合管理、基于风险偏好的评价体系、基于研究团队的宏观判断）。近年来，Rust在投研场景中的应用越来越广泛，除了内存管理能力强、运行速度快之外，还能保证交易策略的高效执行。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/40/06-余天.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    会后，非凸科技有幸采访到部分参会伙伴，他们表达了对数智交易市场的前景展望以及对此次活动的看法，希望这样有深度、有价值的会议持续开展下去，做到更好的开放交流。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News40",
};
</script>

<style></style>
